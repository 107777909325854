@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1676px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1553px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1176px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1675px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1552px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1175px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1175px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1176px) and (max-width: 1552px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1553px) and (max-width: 1675px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

index.css

=====================================================================*/
.main {
  overflow: hidden;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
}
@media (min-width: 768px) {
  .main-view {
    height: 46.87vw;
  }
}
@media (max-width: 767px) {
  .main-view {
    padding-top: 80px;
    height: calc(140.62vw + 80px);
  }
}

.main-view__bg {
  position: absolute;
}
@media (min-width: 768px) {
  .main-view__bg {
    top: 0;
    left: 37.5%;
    width: 62.5%;
  }
}
@media (max-width: 767px) {
  .main-view__bg {
    top: 80px;
    left: 0;
    width: 100%;
    height: 140.62vw;
  }
}
@media (max-width: 767px) {
  .main-view__bg__img__pc {
    display: none;
  }
}
.main-view__bg__img__sp {
  position: absolute;
  width: 100%;
}
@media (min-width: 768px) {
  .main-view__bg__img__sp {
    display: none;
  }
}
.main-view__bg__img__sp.is-top {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.main-view__bg__img__sp.is-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.main-view__inner {
  position: absolute;
  top: 37.5%;
  left: 5%;
  width: 90%;
  z-index: 2;
}
@media (min-width: 768px) {
  .main-view__inner {
    top: 0;
    left: 0;
    padding-left: 6%;
    width: 100%;
    height: 100%;
  }
}

.main-view__txt {
  position: relative;
}

.main-view__catch {
  z-index: 4;
  color: #000000;
}
@media (min-width: 768px) {
  .main-view__catch {
    position: absolute;
    top: 20%;
  }
}
@media (max-width: 767px) {
  .main-view__catch {
    text-align: center;
  }
}
.main-view__catch .is-catch01 {
  display: block;
  font-size: 4.8vw;
  margin-bottom: 2.5vw;
  font-weight: 900;
}
@media (min-width: 768px) {
  .main-view__catch .is-catch01 {
    font-size: 3.28vw;
    margin-bottom: 1.75vw;
  }
}
.main-view__catch .is-catch02 {
  display: block;
  margin-bottom: clamp(1rem, 2vw, 3rem);
}
.main-view__catch .is-catch02 span {
  position: relative;
  display: block;
  color: #ffffff;
  background: #054497;
  padding: 0.5em 1em;
  font-size: 5.8vw;
  line-height: 1;
  letter-spacing: 0.05em;
  font-weight: 900;
}
@media (min-width: 768px) {
  .main-view__catch .is-catch02 span {
    display: inline-block;
    font-size: 3.28vw;
    padding: 0.65em 1.5em 0.75em 0;
  }
  .main-view__catch .is-catch02 span::before {
    position: absolute;
    top: 0;
    right: calc(100% - 1px);
    content: "";
    width: 50vw;
    height: 100%;
    background: #054497;
  }
}
.main-view__catch .is-catch02 span b {
  font-size: 1.14em;
}

@media (min-width: 768px) {
  .main-view__sub {
    position: absolute;
    top: 64%;
  }
}
@media (max-width: 767px) {
  .main-view__sub {
    width: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.main-view__sub dt {
  color: #00A0E9;
  margin-bottom: 0.5em;
  font-size: 3.75vw;
  font-weight: 700;
}
@media (min-width: 768px) {
  .main-view__sub dt {
    font-size: 1.35vw;
  }
}
.main-view__sub dd ul {
  row-gap: 1vw;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .main-view__sub dd ul {
    row-gap: 0.5vw;
  }
}
.main-view__sub dd ul li {
  position: relative;
  padding-left: 1.75em;
  font-size: 3.33vw;
  font-weight: 700;
}
@media (min-width: 768px) {
  .main-view__sub dd ul li {
    font-size: 1vw;
  }
}
.main-view__sub dd ul li::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "■";
  rotate: -45deg;
  color: #00A0E9;
}

/* scroll */
.scroll-line {
  position: absolute;
  right: min(1.5%, 3rem);
  bottom: 0;
  writing-mode: vertical-rl;
  z-index: 4;
}
.scroll-line a {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #00A0E9;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.scroll-line a:hover {
  color: #008bf5;
}
.scroll-line__txt {
  letter-spacing: 0;
}
.scroll-line span {
  content: "";
  inline-size: 5rem;
  block-size: 1px;
  background: transparent;
  display: block;
  position: relative;
  overflow: hidden;
}
.scroll-line span::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: #00A0E9;
  transition: all 0.3s ease 0s;
  block-size: 1px;
  inline-size: 100%;
  animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
}

@keyframes scroll-line {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  51% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
  background: #ffffff;
  padding-top: clamp(3rem, 6vw, 6rem);
}

/*--------------------------------------------------------------------/
	top intro
/--------------------------------------------------------------------*/
.top-intro {
  position: relative;
  overflow: hidden;
  padding-bottom: clamp(4rem, 9vw, 9rem);
}
.top-intro__inner {
  display: flex;
}
@media (min-width: 1176px) {
  .top-intro__inner {
    column-gap: 5%;
    row-gap: clamp(3rem, 6vw, 6rem);
  }
}
@media (max-width: 1175px) {
  .top-intro__inner {
    flex-direction: column;
    row-gap: clamp(6rem, 9vw, 9rem);
  }
}

.top-news {
  overflow: hidden;
}
@media (min-width: 1176px) {
  .top-news {
    width: 65.85%;
  }
}
.top-news__ttl {
  color: #00A0E9;
  text-transform: capitalize;
  margin-bottom: 1.5rem;
  line-height: 1;
  font-weight: 600;
  font-size: clamp(16px, 5.71vw, 36px);
}
@media (min-width: 1176px) {
  .top-news__ttl {
    font-size: clamp(20px, 1.85vw, 36px);
  }
}
@media (min-width: 1676px) {
  .top-news__ttl {
    font-size: 2.25rem;
  }
}
@media (min-width: 1176px) {
  .top-news .common-category__list li a {
    font-size: 0.8vw;
  }
}
@media (min-width: 1676px) {
  .top-news .common-category__list li a {
    font-size: 0.8em;
  }
}
.top-news .btn-wrap {
  margin-top: clamp(1.5em, 2vw, 2rem);
  max-width: 360px;
}
@media (min-width: 768px) {
  .top-news .btn-wrap {
    float: right;
    width: 100%;
  }
}
@media (min-width: 1921px) and (min-width: 1921px) {
  .top-news .btn-wrap {
    max-width: 18.75vw;
  }
}
@media (max-width: 767px) {
  .top-news .btn-wrap {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.top-news__list {
  margin-top: 1.5em;
  row-gap: 0.5em;
  display: flex;
  flex-direction: column;
}
.top-news__list li a {
  display: grid;
  gap: 1em 1em;
  align-items: center;
  padding: 1em 1.5em;
  color: #222222;
  background: #F6F6F6;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
}
@media (min-width: 768px) {
  .top-news__list li a {
    grid-template-columns: auto max(150px, 20%) 1fr;
    grid-template-rows: 1fr;
  }
}
@media (max-width: 767px) {
  .top-news__list li a {
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
  }
}
.top-news__list li a .is-date {
  color: #00A0E9;
  letter-spacing: 0;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
@media (min-width: 1176px) {
  .top-news__list li a .is-date {
    font-size: clamp(11px, 1.02vw, 17px);
  }
}
@media (max-width: 767px) {
  .top-news__list li a .is-date {
    grid-column: 1;
    grid-row: 1;
  }
}
.top-news__list li a .is-category {
  row-gap: 0.4em;
  display: flex;
}
@media (min-width: 768px) {
  .top-news__list li a .is-category {
    flex-direction: column;
    font-size: clamp(11px, 1.43vw, 14px);
  }
}
@media (min-width: 1176px) {
  .top-news__list li a .is-category {
    font-size: clamp(10px, 0.93vw, 14px);
  }
}
@media (min-width: 1553px) {
  .top-news__list li a .is-category {
    font-size: 0.9em;
  }
}
@media (max-width: 767px) {
  .top-news__list li a .is-category {
    grid-column: 2;
    grid-row: 1;
    column-gap: 0.4em;
    flex-wrap: wrap;
    width: fit-content;
  }
}
.top-news__list li a .is-category li {
  width: 100%;
}
.top-news__list li a .category-item {
  background: #ffffff;
  color: #00A0E9;
  width: 100%;
  letter-spacing: 0;
}
.top-news__list li a .is-title {
  line-height: 1.4;
  font-weight: 700;
}
@media (min-width: 1176px) {
  .top-news__list li a .is-title {
    font-size: clamp(11px, 1.02vw, 17px);
  }
}
@media (max-width: 767px) {
  .top-news__list li a .is-title {
    grid-column: 1/3;
    grid-row: 2;
  }
}

@media (min-width: 1176px) {
  .top-banner {
    width: 29.15%;
  }
}
@media (max-width: 1175px) {
  .top-banner {
    max-width: 480px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

/*--------------------------------------------------------------------/
	top activity
/--------------------------------------------------------------------*/
.top-list {
  margin-bottom: clamp(9rem, 14vw, 14rem);
}
.top-list__inner {
  margin-left: auto !important;
  margin-right: auto !important;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .top-list__inner {
    row-gap: clamp(1.5em, 2vw, 2em);
    padding-left: 3%;
    padding-right: 3%;
  }
}

.top-list__item {
  display: grid;
}
@media (max-width: 767px) {
  .top-list__item {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
}
.top-list__item:nth-of-type(odd) {
  background: #00A0E9;
}
@media (min-width: 768px) {
  .top-list__item:nth-of-type(odd) {
    grid-template-rows: 1fr;
    grid-template-columns: 56% 44%;
  }
  .top-list__item:nth-of-type(odd) .top-list__item__txt {
    grid-column: 1;
  }
  .top-list__item:nth-of-type(odd) .top-list__item__img {
    grid-column: 2;
  }
}
@media (min-width: 1176px) {
  .top-list__item:nth-of-type(odd) {
    grid-template-columns: 36.44% 63.56%;
  }
}
.top-list__item:nth-of-type(odd) .top-list__item__txt__btn a {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #00A0E9;
  border: 1px solid;
  border-color: #ffffff;
  padding: 1em 4%;
  width: 100%;
  font-size: 1.25em;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  border-radius: 999px;
}
.top-list__item:nth-of-type(odd) .top-list__item__txt__btn a:hover {
  color: #ffffff;
  background-color: #008bf5;
  border-color: #008bf5;
}
.top-list__item:nth-of-type(odd) .top-list__item__txt__btn a:hover i {
  background: #ffffff;
}
.top-list__item:nth-of-type(odd) .top-list__item__txt__btn a:hover i::before {
  color: #008bf5;
}
.top-list__item:nth-of-type(odd) .top-list__item__txt__btn a::before {
  content: "";
  justify-self: start;
}
.top-list__item:nth-of-type(odd) .top-list__item__txt__btn a i {
  position: relative;
  width: 32px;
  height: 32px;
  content: "";
  background: #008bf5;
  justify-self: end;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}
.top-list__item:nth-of-type(odd) .top-list__item__txt__btn a i::before {
  font-size: 0.8em;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
}
.top-list__item:nth-of-type(even) {
  background: #054497;
}
@media (min-width: 768px) {
  .top-list__item:nth-of-type(even) {
    grid-template-rows: 1fr;
    grid-template-columns: 56% 44%;
  }
  .top-list__item:nth-of-type(even) .top-list__item__txt {
    grid-column: 2/3;
  }
  .top-list__item:nth-of-type(even) .top-list__item__img {
    grid-column: 1/2;
    grid-row: 1/2;
  }
}
@media (min-width: 1176px) {
  .top-list__item:nth-of-type(even) {
    grid-template-columns: 63.56% 36.44%;
  }
}
.top-list__item:nth-of-type(even) .top-list__item__txt__btn a {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #054497;
  border: 1px solid;
  border-color: #ffffff;
  padding: 1em 4%;
  width: 100%;
  font-size: 1.25em;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  position: relative;
  line-height: 1.4;
  font-weight: 700;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  border-radius: 999px;
}
.top-list__item:nth-of-type(even) .top-list__item__txt__btn a:hover {
  color: #ffffff;
  background-color: #008bf5;
  border-color: #008bf5;
}
.top-list__item:nth-of-type(even) .top-list__item__txt__btn a:hover i {
  background: #ffffff;
}
.top-list__item:nth-of-type(even) .top-list__item__txt__btn a:hover i::before {
  color: #008bf5;
}
.top-list__item:nth-of-type(even) .top-list__item__txt__btn a::before {
  content: "";
  justify-self: start;
}
.top-list__item:nth-of-type(even) .top-list__item__txt__btn a i {
  position: relative;
  width: 32px;
  height: 32px;
  content: "";
  background: #008bf5;
  justify-self: end;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}
.top-list__item:nth-of-type(even) .top-list__item__txt__btn a i::before {
  font-size: 0.8em;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease 0s;
}

.top-list__item__txt {
  color: #ffffff;
  padding: clamp(3em, 4vw, 4em) clamp(1.5em, 8%, 4em) clamp(3em, 5vw, 5em);
}
@media (min-width: 768px) {
  .top-list__item__txt {
    padding: clamp(3em, 4vw, 4em) clamp(1.5em, 10%, 10em) clamp(3em, 4vw, 4em);
  }
}
@media (min-width: 1176px) {
  .top-list__item__txt {
    padding: clamp(3em, 4vw, 4em) clamp(1.5em, 14%, 14em) clamp(3em, 4vw, 4em);
  }
}
@media (max-width: 767px) {
  .top-list__item__txt {
    grid-row: 2;
  }
}
.top-list__item__txt__ttl {
  position: relative;
  margin-bottom: clamp(1.5em, 2.5vw, 2.5em);
}
.top-list__item__txt__ttl em {
  display: block;
  font-style: normal;
  margin-bottom: 0.75em;
  opacity: 0.5;
  font-size: clamp(10px, 3.57vw, 27px);
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
@media (min-width: 768px) {
  .top-list__item__txt__ttl em {
    font-size: clamp(9px, 1.17vw, 27px);
  }
}
.top-list__item__txt__ttl b {
  font-size: clamp(14px, 5vw, 44px);
}
@media (min-width: 768px) {
  .top-list__item__txt__ttl b {
    font-size: clamp(18px, 2.34vw, 44px);
  }
}
.top-list__item__txt__ttl b strong {
  font-size: 1.5em;
  font-weight: 700;
}
@media (min-width: 768px) {
  .top-list__item__txt__txt {
    font-size: clamp(8px, 1.04vw, 18px);
  }
}
.top-list__item__txt__btn {
  margin-top: 1.5em;
}
.top-list__item__txt__btn a span {
  font-size: clamp(10px, 3.57vw, 18px);
}
@media (min-width: 768px) {
  .top-list__item__txt__btn a span {
    font-size: clamp(12px, 1.56vw, 18px);
  }
}
@media (min-width: 1176px) {
  .top-list__item__txt__btn a span {
    font-size: clamp(12px, 1.11vw, 18px);
  }
}
@media (min-width: 768px) {
  .top-list__item__txt__btn a i {
    font-size: clamp(12px, 1.56vw, 18px);
  }
}
@media (max-width: 767px) {
  .top-list__item__img {
    grid-row: 1;
  }
}

/*--------------------------------------------------------------------/
	top activity
/--------------------------------------------------------------------*/
.top-activity {
  position: relative;
  padding-top: clamp(3rem, 6vw, 6rem);
  padding-bottom: clamp(6em, 9vw, 9rem);
  background: #DFEFFE;
}
.top-activity::before {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 40%;
  background: #C8E2F9;
}
.top-activity__wrap {
  overflow: hidden;
}
.top-activity__add {
  position: absolute;
  top: -13vw;
  left: 46%;
  width: 50%;
}
@media (min-width: 768px) {
  .top-activity__add {
    top: -8vw;
    left: 52%;
    width: 38.95%;
  }
}

.top-activity__inner {
  position: relative;
  z-index: 2;
  margin-left: auto !important;
  margin-right: auto !important;
}
.top-activity__ttl {
  margin-bottom: clamp(2em, 3vw, 3em);
}
@media (max-width: 767px) {
  .top-activity__ttl {
    padding-left: 1.5em;
  }
}
.top-activity__ttl em {
  position: relative;
  display: block;
  font-style: normal;
  text-transform: capitalize;
  color: #00A0E9;
  line-height: 1.2;
  font-size: calc(2.5rem + 40 * (100vw - 280px) / 1300);
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
@media (min-width: 1553px) {
  .top-activity__ttl em {
    font-size: 5.38rem;
  }
}
.top-activity__ttl em::before {
  position: absolute;
  top: calc(50% - 1px);
  right: calc(100% + 0.5em);
  content: "";
  width: 50vw;
  height: 2px;
  background: #00A0E9;
}
.top-activity__ttl b {
  font-size: calc(1.14rem + 12 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .top-activity__ttl b {
    font-size: 2rem;
  }
}
.top-activity__txt {
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
@media (min-width: 1553px) {
  .top-activity__txt {
    font-size: 1.14em;
  }
}
.top-activity__list {
  display: grid;
}
@media (min-width: 768px) {
  .top-activity__list {
    grid-template-columns: repeat(3, 1fr);
    gap: 2em 2%;
  }
}
@media (max-width: 767px) {
  .top-activity__list {
    grid-template-columns: 1fr;
    row-gap: 1.5em;
    max-width: 480px;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
.top-activity__notes {
  margin-top: 1.5em;
}
.top-activity .btn-wrap {
  margin-top: 1.5em;
  display: flex;
  justify-content: center;
}
@media (min-width: 768px) {
  .top-activity .btn-wrap {
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------------/
	top voice
/--------------------------------------------------------------------*/
.top-voice {
  padding-top: clamp(6rem, 9vw, 9rem);
  padding-bottom: clamp(6rem, 9vw, 9rem);
}
.top-voice__ttl {
  position: relative;
  text-align: center;
  padding-bottom: 1.5em;
  margin-bottom: clamp(2em, 3vw, 3em);
}
.top-voice__ttl::before {
  position: absolute;
  bottom: 0;
  left: calc(50% - 1em);
  content: "";
  width: 2em;
  height: 3px;
  border-radius: 999px;
  background: #00A0E9;
}
.top-voice__ttl em {
  font-style: normal;
  display: block;
  color: #00A0E9;
  font-family: 'Poppins', sans-serif;
  line-height: 1;
  font-size: calc(2.5rem + 50 * (100vw - 280px) / 1300);
  font-weight: 600;
}
@media (min-width: 1553px) {
  .top-voice__ttl em {
    font-size: 5.63rem;
  }
}
.top-voice__ttl b {
  display: block;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1300);
  font-weight: 700;
}
@media (min-width: 1553px) {
  .top-voice__ttl b {
    font-size: 2rem;
  }
}
.top-voice .btn-wrap {
  padding-top: clamp(3rem, 4vw, 4rem);
  max-width: 420px;
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (min-width: 1921px) {
  .top-voice .btn-wrap {
    max-width: 21.87vw;
  }
}