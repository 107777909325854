@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

index.css

=====================================================================*/
.main {
	overflow: hidden;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
$top_sp_hight: floor-decimal(calc(675 / 480) * 100) * 1vw; //スマホの高さ
$top_tb_hight: 100vw;
$top_pc_hight: 900px;
.main-view {
	position: relative;
	@include media-breakpoint-up(md) {
		height: floor-decimal(calc(900 / 1920) * 100) * 1vw;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 80px;
		height: calc($top_sp_hight + 80px);
	}
}

.main-view__bg {
	position: absolute;
	@include media-breakpoint-up(md) {
		top: 0;
		left: 37.5%;
		width: 62.5%;
	}
	@include media-breakpoint-down(sm) {
		top: 80px;
		left: 0;
		width: 100%;
		height: $top_sp_hight;
	}
	&__img {
		&__pc {
			@include media-breakpoint-down(sm) {
				display: none;
			}
		}
		&__sp {
			position: absolute;
			width: 100%;
			@include media-breakpoint-up(md) {
				display: none;
			}
			&.is-top {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
			&.is-bottom {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
			}
		}
	}
}

.main-view__inner {
	position: absolute;
	top: 37.5%;
	left: 5%;
	width: 90%;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(md) {
		top: 0;
		left: 0;
		padding-left: 6%;
		width: 100%;
		height: 100%;
	}
}
.main-view__txt {
	position: relative;
}
.main-view__catch {
	z-index: z-index(module, part04);
	color: $black;
	@include media-breakpoint-up(md) {
		position: absolute;
		top: 20%;
	}
	@include media-breakpoint-down(sm) {
		text-align: center;
	}
	.is-catch01 {
		display: block;
		font-size: 4.8vw;
		margin-bottom: 2.5vw;
		@include f-w(900);
		@include media-breakpoint-up(md) {
			font-size: 3.28vw;
			margin-bottom: 1.75vw;
		}
	}
	.is-catch02 {
		display: block;
		margin-bottom: clamp(1rem, 2vw, 3rem);
		span {
			position: relative;
			display: block;
			color: $white;
			background: $sub_c;
			padding: 0.5em 1em;
			font-size: 5.8vw;
			@include line-h(1);
			@include l-sp(0.05em);
			@include f-w(900);
			@include media-breakpoint-up(md) {
				display: inline-block;
				font-size: 3.28vw;
				padding: 0.65em 1.5em 0.75em 0;
				&::before {
					position: absolute;
					top: 0;
					right: calc(100% - 1px);
					content: "";
					width: 50vw;
					height: 100%;
					background: $sub_c;
				}
			}
			b {
				font-size: 1.14em;
			}
		}
	}
}

.main-view__sub {
	@include media-breakpoint-up(md) {
		position: absolute;
		top: 64%;
	}
	@include media-breakpoint-down(sm) {
		width: fit-content;
		@include m-a;
	}
	dt {
		color: $main_c;
		margin-bottom: 0.5em;
		font-size: 3.75vw;
		@include f-w(700);
		@include media-breakpoint-up(md) {
			font-size: 1.35vw;
		}
	}
	dd {
		ul {
			row-gap: 1vw;
			@include flex-column;
			@include media-breakpoint-up(md) {
				row-gap: 0.5vw;
			}
			li {
				position: relative;
				padding-left: 1.75em;
				font-size: 3.33vw;
				@include f-w(700);
				@include media-breakpoint-up(md) {
					font-size: 1vw;
				}
				&::before {
					position: absolute;
					top: 0;
					left: 0;
					content: "■";
					rotate: -45deg;
					color: $main_c;
				}
			}
		}
	}
}

/* scroll */
.scroll-line {
	position: absolute;
	//left: unquote("min(2%,4rem)");
	right: min(1.5%, 3rem);
	bottom: 0;
	writing-mode: vertical-rl;
	z-index: z-index(module, part04);
	a {
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: $main_c;
		@include transition;
		@include dec-none;
		@include f-family(font02);
		@include f-w(600);
		&:hover {
			color: $hover_c;
		}
	}
	&__txt {
		@include l-sp(0);
	}
	span {
		content: "";
		inline-size: 5rem;
		block-size: 1px;
		background: transparent;
		display: block;
		position: relative;
		overflow: hidden;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: $main_c;
			@include transition;
			block-size: 1px;
			inline-size: 100%;
			animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
		}
	}
}

@keyframes scroll-line {
	0% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	51% {
		transform: scale(1, 1);
		transform-origin: 0 100%;
	}
	100% {
		transform: scale(1, 0);
		transform-origin: 0 100%;
	}
}
/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
	background: $white;
	padding-top: clamp(3rem, 6vw, 6rem);
}

/*--------------------------------------------------------------------/
	top intro
/--------------------------------------------------------------------*/
// top-intro
.top-intro {
	position: relative;
	overflow: hidden;
	padding-bottom: clamp(4rem, 9vw, 9rem);
	&__inner {
		display: flex;
		@include media-breakpoint-up(lg) {
			column-gap: 5%;
			row-gap: clamp(3rem, 6vw, 6rem);
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(6rem, 9vw, 9rem);
		}
	}
}

// top news
.top-news {
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		width: 65.85%;
	}
	&__ttl {
		color: $main_c;
		text-transform: capitalize;
		margin-bottom: 1.5rem;
		@include line-h(1);
		@include f-w(600);
		@include fs_xxs(16, 36);
		@include media-breakpoint-up(lg) {
			@include fs_lg(20, 36);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(36);
		}
	}
	.common-category__list {
		li {
			a {
				@include media-breakpoint-up(lg) {
					font-size: 0.8vw;
				}
				@include media-breakpoint-up(xxl) {
					font-size: 0.8em;
				}
			}
		}
	}
	.btn-wrap {
		margin-top: clamp(1.5em, 2vw, 2rem);
		max-width: 360px;
		@include media-breakpoint-up(md) {
			float: right;
			width: 100%;
		}
		@include media-breakpoint-up(full) {
			@include media-breakpoint-up(full) {
				max-width: floor-decimal(calc(360 / 1920), 4) * 100vw;
			}
		}
		@include media-breakpoint-down(sm) {
			width: 100%;
			@include m-a;
		}
	}
}

.top-news {
	&__list {
		margin-top: 1.5em;
		row-gap: 0.5em;
		@include flex-column;
		li {
			a {
				display: grid;
				gap: 1em 1em;
				align-items: center;
				padding: 1em 1.5em;
				color: $txt_c;
				background: $p-gray;
				@include dec-none;
				@include transition;
				@include media-breakpoint-up(md) {
					grid-template-columns: auto max(150px, 20%) 1fr;
					grid-template-rows: 1fr;
				}
				@include media-breakpoint-down(sm) {
					grid-template-columns: auto 1fr;
					grid-template-rows: auto 1fr;
				}
				.is-date {
					color: $main_c;
					@include l-sp(0);
					@include f-family(font02);
					@include f-w(500);
					@include media-breakpoint-up(lg) {
						@include fs_lg(11, 17);
					}
					@include media-breakpoint-down(sm) {
						grid-column: 1;
						grid-row: 1;
					}
				}
				.is-category {
					row-gap: 0.4em;
					display: flex;
					
					@include media-breakpoint-up(md) {
						flex-direction: column;
						@include fs_md(11,14);
					}
					@include media-breakpoint-up(lg) {
						@include fs_lg(10, 14);
					}
					@include media-breakpoint-up(xl) {
						font-size: 0.9em;
					}
					@include media-breakpoint-down(sm) {
						grid-column: 2;
						grid-row: 1;
						column-gap: 0.4em;
						flex-wrap: wrap;
						width: fit-content;
					}
					li {
						width: 100%;
					}
				}
				.category-item {
					background: $white;
					color: $main_c;
					width: 100%;
					@include l-sp(0);
				}
				.is-title {
					@include line-h(1.4);
					@include f-w(700);
					@include media-breakpoint-up(lg) {
						@include fs_lg(11, 17);
					}
					@include media-breakpoint-down(sm) {
						grid-column: 1 / 3;
						grid-row: 2;
					}
				}
			}
		}
	}
}

.top-banner {
	@include media-breakpoint-up(lg) {
		width: 29.15%;
	}
	@include media-breakpoint-down(md) {
		max-width: 480px;
		@include m-a;
	}
}

/*--------------------------------------------------------------------/
	top activity
/--------------------------------------------------------------------*/
.top-list {
	margin-bottom: clamp(9rem, 14vw, 14rem);
	&__inner {
		@include m-a;
		@include flex-column;
		@include media-breakpoint-up(md) {
			row-gap: clamp(1.5em, 2vw, 2em);
			padding-left: 3%;
			padding-right: 3%;
		}
	}
}

.top-list__item {
	display: grid;
	@include media-breakpoint-down(sm) {
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr;
	}
	&:nth-of-type(odd) {
		background: $main_c;
		@include media-breakpoint-up(md) {
			grid-template-rows: 1fr;
			grid-template-columns: 56% 44%;
			.top-list__item__txt {
				grid-column: 1;
			}
			.top-list__item__img {
				grid-column: 2;
			}
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: 36.44% 63.56%;
		}
		.top-list__item__txt__btn {
			a {
				@include btn-arrow($white, $main_c, $white, $white, $hover_c, $white);
			}
		}
	}
	&:nth-of-type(even) {
		background: $sub_c;
		@include media-breakpoint-up(md) {
			grid-template-rows: 1fr;
			grid-template-columns: 56% 44%;
			.top-list__item__txt {
				grid-column: 2 / 3;
			}
			.top-list__item__img {
				grid-column: 1 / 2;
				grid-row: 1 / 2;
			}
		}
		@include media-breakpoint-up(lg) {
			grid-template-columns: 63.56% 36.44%;
		}
		.top-list__item__txt__btn {
			a {
				@include btn-arrow($white, $sub_c, $white, $white, $hover_c, $white);
			}
		}
	}
}

.top-list__item {
	&__txt {
		color: $white;
		padding: clamp(3em, 4vw, 4em) clamp(1.5em, 8%, 4em) clamp(3em, 5vw, 5em);
		@include media-breakpoint-up(md) {
			padding: clamp(3em, 4vw, 4em) clamp(1.5em, 10%, 10em) clamp(3em, 4vw, 4em);
		}
		@include media-breakpoint-up(lg) {
			padding: clamp(3em, 4vw, 4em) clamp(1.5em, 14%, 14em) clamp(3em, 4vw, 4em);
		}
		@include media-breakpoint-down(sm) {
			grid-row: 2;
		}
		&__ttl {
			position: relative;
			margin-bottom: clamp(1.5em, 2.5vw, 2.5em);
			em {
				display: block;
				font-style: normal;
				margin-bottom: 0.75em;
				opacity: 0.5;
				@include fs-xxs(10,27);
				@include f-w(600);
				@include f-family(font02);
				@include media-breakpoint-up(md) {
					@include fs_md(9, 27);
				}
			}
			b {
				@include fs_xxs(14, 44);
				@include media-breakpoint-up(md) {
					@include fs_md(18, 44);
				}
				strong {
					font-size: 1.5em;
					@include f-w(700);
				}
			}
		}
		&__txt {
			@include media-breakpoint-up(md) {
				@include fs_md(8, 18);
			}
		}
		&__btn {
			margin-top: 1.5em;
			a {
				span {
					@include fs_xxs(10, 18);
					@include media-breakpoint-up(md) {
						@include fs_md(12, 18);
					}
					@include media-breakpoint-up(lg) {
						@include fs_lg(12, 18);
					}
				}
				i {
					@include media-breakpoint-up(md) {
						@include fs_md(12, 18);
					}
				}
			}
		}
	}
	&__img {
		@include media-breakpoint-down(sm) {
			grid-row: 1;
		}
	}
}

/*--------------------------------------------------------------------/
	top activity
/--------------------------------------------------------------------*/
.top-activity {
	position: relative;
	padding-top: clamp(3rem, 6vw, 6rem);
	padding-bottom: clamp(6em, 9vw, 9rem);
	background: $bg_c1;
	&::before {
		position: absolute;
		bottom: 0;
		left: 0;
		content: "";
		width: 100%;
		height: 40%;
		background: $bg_c2;
	}
	&__wrap {
		overflow: hidden;
	}
	&__add {
		position: absolute;
		top: -13vw;
		left: 46%;
		width: 50%;
		@include media-breakpoint-up(md) {
			top: -8vw;
			left: 52%;
			width: 38.95%;
		}
	}
}

.top-activity {
	&__inner {
		position: relative;
		z-index: z-index(module, part02);
		@include m-a;
	}
	&__ttl {
		margin-bottom: clamp(2em, 3vw, 3em);
		@include media-breakpoint-down(sm) {
			padding-left: 1.5em;
		}
		em {
			position: relative;
			display: block;
			font-style: normal;
			text-transform: capitalize;
			color: $main_c;
			@include line-h(1.2);
			@include f-s_xxs(2.5, 40);
			@include f-w(600);
			@include f-family(font02);
			@include media-breakpoint-up(xl) {
				@include f-size(86);
			}
			&::before {
				position: absolute;
				top: calc(50% - 1px);
				right: calc(100% + 0.5em);
				content: "";
				width: 50vw;
				height: 2px;
				background: $main_c;
			}
		}
		b {
			@include f-s_xxs(1.14, 12);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
	}
	&__txt {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include media-breakpoint-up(xl) {
			font-size: 1.14em;
		}
	}
	&__list {
		display: grid;
		@include media-breakpoint-up(md) {
			grid-template-columns: repeat(3, 1fr);
			gap: 2em 2%;
		}
		@include media-breakpoint-down(sm) {
			grid-template-columns: 1fr;
			row-gap: 1.5em;
			max-width: 480px;
			@include m-a;
		}
	}
	&__notes {
		margin-top: 1.5em;
	}
	.btn-wrap {
		margin-top: 1.5em;
		display: flex;
		justify-content: center;
		@include media-breakpoint-up(md) {
			justify-content: flex-end;
		}
	}
}

/*--------------------------------------------------------------------/
	top voice
/--------------------------------------------------------------------*/
// top-voice
.top-voice {
	padding-top: clamp(6rem, 9vw, 9rem);
	padding-bottom: clamp(6rem, 9vw, 9rem);
	&__ttl {
		position: relative;
		text-align: center;
		padding-bottom: 1.5em;
		margin-bottom: clamp(2em, 3vw, 3em);
		&::before {
			position: absolute;
			bottom: 0;
			left: calc(50% - 1em);
			content: "";
			width: 2em;
			height: 3px;
			@include radius(999px);
			background: $main_c;
		}
		em {
			font-style: normal;
			display: block;
			color: $main_c;
			@include f-family(font02);
			@include line-h(1);
			@include f-s_xxs(2.5, 50);
			@include f-w(600);
			@include media-breakpoint-up(xl) {
				@include f-size(90);
			}
		}
		b {
			display: block;
			@include f-s_xxs(1, 12);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
	}
	.btn-wrap {
		padding-top: clamp(3rem, 4vw, 4rem);
		max-width: 420px;
		@include m-a;
		@include media-breakpoint-up(full) {
      max-width: floor-decimal(calc(420 / 1920), 4) * 100vw;
    }
	}
}
